import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="render-feed-form"
export default class extends Controller {
    feedCounter = 0;
    limit = 0;

    connect() {
        this.element.addEventListener("submit", this.validateFeedForm);
        this.feedCounter = this.element.dataset.feedTotal;
        this.limit = this.element.dataset.limit;
    }

    getPartial = async () => {
        const response = await get(`/categories/feed_field?feed_counter=${this.feedCounter}`)

        const body = await response.html;
        const section = document.querySelector('#feeds');
        const templateElement = document.createElement('template');
        templateElement.innerHTML = body;

        section.appendChild(templateElement.content.firstElementChild);
        this.feedCounter++;

        if (this.feedCounter >= this.limit) {
            document.querySelector('#add-extra').remove();
        }
    }

    removeFeedInput(event) {
        const fieldset = event.currentTarget.closest('fieldset');

        fieldset.remove();
    }

    validateFeedForm = (event) => {
        let isFormValid = true;
        const feedSections = document.querySelectorAll('.feed-url-input');
        feedSections.forEach(section => {
            const input = section.querySelector('input');
            const errorSpan = section.querySelector('span');

            if (!this.isValidFeedAlertURL(input.value)) {
                input.classList.add('border-rose-700');
                errorSpan.classList.remove('hidden');
                isFormValid = false;
            } else {
                input.classList.remove('border-rose-700');
                errorSpan.classList.add('hidden');
            }
        })

        if(!isFormValid){
            event.preventDefault();
        }
    }

    isValidFeedAlertURL(url) {
        const regex = /^https:\/\/www\.google\.com\/alerts\/feeds\/\d+\/\d+$/;
        return regex.test(url);
    }
}
